
@media only screen and (max-width: 865px) {
  .intro {
    margin-top: 20vh;
  }

  .map-container {
    padding: 0;
  }

  .map {
    margin-top: 25px;
  }
}

@media only screen and (max-width: 680px) {

  .nav-link {
    padding-left: 0;
    padding-right: 0;
  }

  .intro {
    margin-top: 15vh;
  }

  .intro h1 {
    font-size: 62px;
  }

  .intro h4 {
    font-size: 21px;
  }

  .map img {
    min-width: 300px;
  }

  .city-label {
    font-size: 10px;
  }
}

@media only screen and (max-width: 576px) {

  * {
    padding-left: 0;
    padding-right: 0;
  }

  p {
    font-size: 14px;
  }

  a {
    font-size: 16px;
  }

  .intro h1 {
    font-size: 40px;
  }

  .intro h4 {
    font-size: 18px;
  }

  .continue-arrow {
    font-size: 52px;
  }

  .me-outline {
    width: 65%;
  }

  .my-info li {
    font-size: 14px;
  }

  .image-item {
    height: 23vh;
  }

  #projects {
    padding-left: 1em;
    padding-right: 1em;
  }

}

