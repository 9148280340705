:root {
  --main-blue: #50878E;
  --main-purple: #7C3693; 
  --main-gray: #D3D3D3;
  --subtitle-gray: #333333;
  --main-font: "Rubik", sans-serif;
  --section-side-padding: 5em;
  --section-top-padding: 2em;
}

html {
  scroll-behavior: smooth;
}

p {
  font-size: 20px;
  line-height: 1.8em;
}

a {
  color: var(--main-purple);
  font-size: 20px;
  font-weight: 400;
  font-family: var(--main-font);
  text-align: center;
}

a:hover {
  color: var(--main-blue);
  text-decoration: none;
}

h2 {
  color: var(--main-blue);
  font-family: var(--main-font);
}

.sticky-header {
  position: fixed;
  width: 100%;
  background-color: #D3D3D333;
  padding: 0.5em 1em;
  z-index: 2;
}

.sticky-header a {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 32px;
}

.section-layout {
  min-height: 100vh;
  width: 100%;
  padding: var(--section-top-padding) var(--section-side-padding) var(--section-top-padding) var(--section-side-padding);
}

/* Landing section styling */
#landing {
  padding: 0;
}

.nav-parent {
  background-color: white;
  padding: var(--section-top-padding) var(--section-side-padding) var(--section-top-padding) var(--section-side-padding);
  
}

.nav-logo {
  height: 50px;
}

.navbar-light .navbar-nav .nav-link {
  color: var(--main-purple);
  font-size: 20px;
}
.navbar-light .navbar-nav .nav-link:hover {
  color: var(--main-blue);
}

.navbar-light .navbar-toggler {
  border-color: var(--main-purple);
}

.nav-item {
  margin-right: 1em;
}

.intro {
  margin: 30vh 0 0 var(--section-side-padding);
  width: fit-content;
}

.intro h1 {
  color: white;
  font-size: 72px;
  font-family: var(--main-font);
}

.intro h4 {
  color: var(--main-gray);
  font-size: 48px;
}

.intro a {
  display: block;
}

.continue-arrow {
  margin-top: 0.2em;
  cursor: pointer;
  font-size: 75px;
  color: white;
  text-align: center;
}

.continue-arrow:hover {
  color: var(--main-purple);
}

.intro-bg {
  background: var(--main-blue);
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  z-index: -2;
}

.me-outline {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 50%;
  
}

/* About Me section styling */

#about {
  text-align: center;
  margin-bottom: var(--section-top-padding);
}

.section-header {
  padding: 2em 0 0 0;
  margin-bottom: 0;
  font-size: 52px;
  text-align: center;
}

.section-subheader {
  padding-bottom: 0.5em;
}

.my-info {
  list-style: none;
}

.my-info li {
  line-height: 1.8em;
  padding: 0.75em;
  font-size: 18px;
}

.tech-logo-section {
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 90%;
}

.tech-logo-section .col-6 {
  text-align: center;
}

.tech-logo {
  max-width: 175px;
  width: 75%;
  padding: 10%;
}

.map-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.map-container {
  text-align: center;
  justify-content: center;
  min-height: 750px;
}

.map-container h3 {
  margin-top: var(--section-top-padding);
  margin-bottom: 0.5em;
}

.map-container {
  padding: 0 var(--section-side-padding);
}

.map-col {
  padding-top: 3.5em;
  width: fit-content;
  max-width: 100%;
}

.map {
  margin-top: 100px;
  position: relative;
  width: fit-content;
}

.map img {
  width: 100%;
  min-width: 500px;
}

.city-label {
  max-width: 7rem;
  background-color: var(--main-gray);
  opacity: 0.8;
  border-radius: 5px;
  padding: 4px;
  position: absolute;
}

.city-label:hover{
  cursor: pointer;
  opacity: 1;
}

#seattle {
  top: 6.5%;
  left: 5.5%;
}

#san-diego {
  top: 63.5%;
  left: 7%;
}

#rochester {
  top: 19.8%;
  left: 74.5%;
}

#charlottesville {
  top: 40%;
  left: 70%;
}

#new-milford {
  top: 23.5%;
  left: 90.5%;
}

/* Projects section styling */
#projects {
  background-color: var(--main-blue);
  color: white;
}

.slide {
  width: 85%;
  margin: 0 auto;
}

.image-item {
  height: 30vh;
  margin-top: var(--section-top-padding);
}

.image-item img {
  max-width: 90%;
  height: 100%;
  display: block;
  margin: 0 auto;
}

.carousel-inner {
  width: 85%;
  margin: 0 auto;
}

.project-row {
  padding-top: var(--section-side-padding);
}

#projects .section-header {
  text-align: center;
  padding-top: var(--section-top-padding);
  font-family: var(--main-font);
  color: white;
}


.project-info {
  margin-top: var(--section-top-padding);
  padding-bottom: var(--section-side-padding);
  text-align: center;
}

.project-info a {
  font-weight: 500;
}

.project-info a:hover {
  color: var(--main-gray);
}

/* Contact Section */
#contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  width: 100%;
}

#contact .row {
  margin-top: var(--section-top-padding);
}

.tagline, .contact-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 var(--section-top-padding) 10em var(--section-top-padding);
}

.tagline {
  align-items: flex-end;
}

.contact-info .row {
  margin-bottom: 1.8em;
  margin-left: var(--section-side-padding);
}

.contact-info .row h6 {
  font-family: var(--main-font);
  font-size: 20px;
  font-weight: 400;
}

.contact-info svg {
  color: var(--main-purple);
  font-size: 28px;
}

.contact-line {
  margin-left: 1em;
}

.profile-pic {
  border-radius: 50%;
  width: 250px;
  height: 250px;
  margin-right: var(--section-side-padding);
}

footer {
  width: 100%;
  margin: 0;
  padding: var(--section-top-padding) var(--section-side-padding);
  background-color: var(--main-blue);
  color: white;
}
